import React, { useMemo, useRef, useState } from 'react'
import { Swiper } from 'swiper/react'
import { Navigation, Pagination, Autoplay, Grid } from 'swiper'
import { Swiper as SwiperType } from 'swiper/types'
import cn from 'classnames'

import { ArrowCircleLeftIcon } from '@components/icons/ArrowCircleLeftIcon'
import { ArrowCircleRightIcon } from '@components/icons/ArrowCircleRightIcon'
import { CustomSwiperProps, SlideButtonProps } from './interface'
import { BannerPagination } from '../BannerPagination'

function PrevButton({ className, iconClassName, swiperRef }: SlideButtonProps) {
  return (
    <button
      type='button'
      className={cn(
        'absolute z-[1] top-1/2 translate-y-[-50%] h-[50px] cursor-pointer tablet-mobile:hidden select-none',
        className
      )}
      onClick={() => {
        if (swiperRef.current) swiperRef.current.slidePrev()
      }}
    >
      <ArrowCircleLeftIcon
        width='50px'
        height='50px'
        className={iconClassName}
      />
    </button>
  )
}

function NextButton({ className, iconClassName, swiperRef }: SlideButtonProps) {
  return (
    <button
      type='button'
      className={cn(
        'absolute z-[1] top-1/2 translate-y-[-50%] h-[50px] cursor-pointer tablet-mobile:hidden select-none',
        className
      )}
      onClick={() => {
        if (swiperRef.current) swiperRef.current.slideNext()
      }}
    >
      <ArrowCircleRightIcon
        width='50px'
        height='50px'
        className={iconClassName}
      />
    </button>
  )
}

function CustomSwiper({
  isShowNavigation,
  children,
  prevclass = 'left-[100px]',
  nextclass = 'right-[100px]',
  IconClass = 'text-transparent',
  loop,
  activeBulletClassname,
  bulletClassname,
  paginationClassName,
  containerClass,
  count = 0,
  handleOnSlideChange,
  ...props
}: CustomSwiperProps & {
  children: React.ReactNode
  className?: string
  containerClass?: string
  isShowNavigation?: boolean
  prevclass?: string
  nextclass?: string
  IconClass?: string
  activeBulletClassname?: string
  bulletClassname?: string
}) {
  const [activeIndex, setActiveIndex] = useState(0)

  const swiperRef = useRef<SwiperType>()

  const modules = useMemo(() => {
    const swiperModules = [Grid, Navigation, Pagination, Autoplay]
    if (props.modules) {
      return swiperModules.concat(props.modules)
    }
    return swiperModules
  }, [props.modules])

  const newProps = {
    ...props,
    loop,
    children,
    modules,
    nextclass,
    prevclass,
  }

  return (
    <div className='w-full h-full relative'>
      <Swiper
        className={containerClass}
        {...newProps}
        onSlideChange={s => {
          setActiveIndex(s.realIndex)
          if (handleOnSlideChange) handleOnSlideChange(s.realIndex)
        }}
        onSwiper={swiper => {
          swiperRef.current = swiper
        }}
      >
        {children}
      </Swiper>
      {!!count && (
        <BannerPagination
          count={count}
          activeIndex={activeIndex}
          activeBulletClassname={activeBulletClassname}
          bulletClassname={bulletClassname}
          className={paginationClassName}
        />
      )}
      {isShowNavigation && (
        <>
          <PrevButton
            className={prevclass}
            iconClassName={IconClass}
            swiperRef={swiperRef}
          />
          <NextButton
            className={nextclass}
            iconClassName={IconClass}
            swiperRef={swiperRef}
          />
        </>
      )}
    </div>
  )
}

export { CustomSwiper as Swiper }
