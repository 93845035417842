import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { DateTime } from 'luxon'

import { usePopupBannerAction } from '@hooks/popupBanner/usePopupBannerAction'
import { useAuthentication } from '@hooks/useAuthentication'
import { PopupBannerType } from '@models/popupBanner/popupBannerType'
import { PopupBannerScreenEnum } from '@interfaces/PopupBannerScreenEnum'
import { useUserActionLogAction } from '@hooks/userActionLog/useUserActionLogAction'
import { UserActionEnum } from '@interfaces/UserActionEnum'
import { GuestPopupType, PopupProps, WithPopupProps } from './interface'

export function withPopup(Component: React.FC<PopupProps>) {
  function WithPopup({ screen }: WithPopupProps) {
    const userActionLogClient = useUserActionLogAction()
    const { isAuthenticated, user, canFetchApi } = useAuthentication()
    const [isClose, setIsClose] = useState(false)
    const popupBannerClient = usePopupBannerAction()
    const { data } = useQuery(
      ['popup', screen, user.id],
      () => popupBannerClient.getPopupBanners(screen),
      {
        onSuccess: () => {
          setIsClose(false)
        },
        enabled: canFetchApi,
      }
    )

    const [guestPopup, setGuestPopup] = useState<GuestPopupType>()

    const popupList = useMemo(() => {
      if (isAuthenticated) return data

      return data?.filter(row => !guestPopup?.ids.includes(row.id))
    }, [data, guestPopup, isAuthenticated])

    async function closeAndDontShowAgain(value: PopupBannerType[]) {
      try {
        document.body.style.overflow = ''
        await popupBannerClient.closePopupBanners({
          ids: value.flatMap(row => row.id),
          screen,
        })
      } finally {
        setIsClose(true)
      }
    }

    async function close(value: PopupBannerType[]) {
      try {
        document.body.style.overflow = ''
        if (isAuthenticated) {
          await popupBannerClient.closePopupBanners({
            ids: undefined,
            screen,
          })
        } else {
          const temp: GuestPopupType = JSON.parse(JSON.stringify(guestPopup))
          temp.ids = temp.ids.concat(value.flatMap(row => row.id))
          setGuestPopup(temp)
          localStorage.setItem('guestPopup', JSON.stringify(temp))
        }
      } finally {
        setIsClose(true)
      }
    }

    async function handleClickPopup(value: PopupBannerType) {
      await userActionLogClient.createUserActionLog({
        popupBannerId: value.id,
        time: 0,
        action: UserActionEnum.CLICK_POPUP_BANNER,
      })
    }

    useEffect(() => {
      const date = DateTime.now().toFormat('dd/LL/yyyy')
      const guestPopupInitialData = {
        ids: [],
        date,
      }
      const guestPopupLocal = localStorage.getItem('guestPopup')
      const guestPopupData = guestPopupLocal
        ? JSON.parse(guestPopupLocal)
        : guestPopupInitialData

      if (date !== guestPopupData.date) {
        guestPopupData.ids = []
        guestPopupData.date = date
        localStorage.setItem('guestPopup', JSON.stringify(guestPopupData))
      }

      setGuestPopup(guestPopupData)
    }, [])

    if (
      !popupList ||
      popupList.length === 0 ||
      isClose ||
      (isAuthenticated &&
        screen === PopupBannerScreenEnum.Home &&
        !user.homeBannerEnable) ||
      (isAuthenticated &&
        screen === PopupBannerScreenEnum.Coin &&
        !user.coinBannerEnable)
    )
      return null

    const componentProps = {
      data: popupList,
      showCloseAndDontShowAgainButton: isAuthenticated,
      closeAndDontShowAgain,
      close,
      handleClickPopup,
    }

    return <Component {...componentProps} />
  }

  return WithPopup
}
