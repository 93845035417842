import { useState } from 'react'
import { SwiperSlide } from 'swiper/react'
import Link from 'next/link'
import Image from 'next/image'
import cn from 'classnames'

import { Button } from '@components/Buttons'
import { Swiper } from '@features/home/components/CustomSwiper'
import { PopupProps } from './interface'
import { TriggerAction } from './TriggerAction'

export function PopupDetail({
  data,
  showCloseAndDontShowAgainButton,
  closeAndDontShowAgain,
  close,
  handleClickPopup,
}: PopupProps) {
  const [activeId, setActiveId] = useState<number>(0)

  return (
    <div className='fixed inset-0 bg-secondary-2/30 bg-opacity-30 z-[100] overflow-auto pt-[80px] pb-[20px]'>
      <div className='max-w-[420px] w-full rounded-[8px] overflow-hidden mobile:max-w-[300px] mx-auto'>
        <Swiper
          slidesPerView={1}
          count={data.length}
          autoplay={
            data.length > 1
              ? { delay: 5000, disableOnInteraction: false }
              : undefined
          }
          loop={data.length > 1}
          handleOnSlideChange={value => setActiveId(value)}
          paginationClassName='absolute left-1/2 -translate-x-1/2 bottom-[0px]'
          speed={1000}
        >
          {data.map((row, index) => (
            <SwiperSlide key={row.id}>
              {row.link ? (
                <Link href={row.link} legacyBehavior>
                  <a
                    className='w-full'
                    target='_blank'
                    onClick={() => handleClickPopup(row)}
                  >
                    <div className='relative w-full pt-[133.33%] '>
                      <Image
                        src={row.imgPath}
                        layout='fill'
                        alt={`popup-${row.id}`}
                        sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                        // priority={index === 0}
                        quality={25}
                      />
                    </div>
                  </a>
                </Link>
              ) : (
                <div className='w-full'>
                  <div className='relative w-full pt-[133.33%]'>
                    <Image
                      src={row.imgPath}
                      layout='fill'
                      alt={`popup-${row.id}`}
                      sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                      // priority={index === 0}
                      quality={25}
                    />
                  </div>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={cn('grid grid-cols-2 p-[20px] bg-white', {
            'space-x-[20px]': showCloseAndDontShowAgainButton,
          })}
        >
          {showCloseAndDontShowAgainButton && (
            <Button
              className='border border-gray-3 text-secondary-1 text-[13px]'
              type='button'
              onClick={() => closeAndDontShowAgain(data)}
            >
              ปิดและไม่ต้องแสดงอีก
            </Button>
          )}
          <Button
            className={cn('bg-primary text-white text-[14px] font-medium', {
              'col-span-2': !showCloseAndDontShowAgainButton,
            })}
            type='button'
            onClick={() => close(data)}
          >
            ปิด
          </Button>
        </div>
      </div>
      <TriggerAction id={activeId} data={data} />
    </div>
  )
}
