import { useEffect, useRef } from 'react'

import { useUserActionLogAction } from '@hooks/userActionLog/useUserActionLogAction'
import { UserActionEnum } from '@interfaces/UserActionEnum'
import { TriggerActionProps } from './interface'

export function TriggerAction({ id, data }: TriggerActionProps) {
  const ids = useRef<number[]>([])
  const userActionLogClient = useUserActionLogAction()

  useEffect(() => {
    if (typeof id === 'number' && data.length && !ids.current.includes(id)) {
      ids.current = ids.current.concat(id)
      userActionLogClient.createUserActionLog({
        popupBannerId: data[id].id,
        time: 0,
        action: UserActionEnum.VIEW_POPUP_BANNER,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data])

  return null
}
