import React from 'react'
import cn from 'classnames'

import { CoinButtonProps } from './Buttons/interface'

function CoinButton({
  children,
  className,
  secondClassName,
  size,
  disabled,
  ...props
}: CoinButtonProps) {
  return (
    <button
      className={cn('relative disabled:cursor-not-allowed', className)}
      disabled={disabled}
      {...props}
    >
      <div
        className='absolute left-0 top-0'
        style={{ width: size, height: size }}
      >
        {disabled ? (
          <img
            className='w-full h-full'
            src='/disabled-coin.webp'
            alt='M Coin'
          />
        ) : (
          <img className='w-full h-full' src='/coin.webp' alt='M Coin' />
        )}
      </div>
      <div
        className={cn(
          'border-[3px] flex font-mitr items-center rounded-[40px] justify-center',
          secondClassName,
          {
            'bg-gray-15 border-gray  ': disabled,
            'bg-yellow border-yellow-1': !disabled,
          }
        )}
        style={{ paddingLeft: size - 3, paddingRight: size / 2 - 3 }}
      >
        <span
          className={cn('font-mitr text-white font-medium whitespace-nowrap', {
            'text-gray': disabled,
          })}
        >
          {children}
        </span>
      </div>
    </button>
  )
}

export default CoinButton
