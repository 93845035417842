import { plainToInstance } from 'class-transformer'

import { gqlApiInstance } from '@client/init'
import { AVAILABLE_POPUP_BANNERS } from '@client/collections/Popup/schemas/availablePopupBanners'
import { PlatformEnum } from '@interfaces/PlatformEnum'
import { PopupBannerScreenEnum } from '@interfaces/PopupBannerScreenEnum'
import { PopupBannerType } from '@models/popupBanner/popupBannerType'
import { CLOSE_POPUP_BANNERS } from '@client/collections/Popup/schemas/closePopupBanners'

export function usePopupBannerAction() {
  async function getPopupBanners(
    screen: PopupBannerScreenEnum
  ): Promise<PopupBannerType[]> {
    const { availablePopupBanners } = await gqlApiInstance.request(
      AVAILABLE_POPUP_BANNERS,
      {
        screen,
        platform: PlatformEnum.WEB,
      }
    )

    return plainToInstance(PopupBannerType, availablePopupBanners as [])
  }

  async function closePopupBanners({
    ids,
    screen,
  }: {
    ids?: number[]
    screen: PopupBannerScreenEnum
  }) {
    await gqlApiInstance.request(CLOSE_POPUP_BANNERS, {
      closePopupBannerInput: {
        ids,
        screen,
        platform: PlatformEnum.WEB,
      },
    })
  }

  return {
    getPopupBanners,
    closePopupBanners,
  }
}
