import cn from 'classnames'

import { BannerPaginationProps } from './interface'

export function BannerPagination({
  count,
  activeIndex,
  activeBulletClassname = '!bg-primary',
  bulletClassname = 'bg-gray',
  className = '',
}: BannerPaginationProps) {
  return (
    <div
      className={cn(
        'w-[112px] overflow-x-hidden mx-auto my-[10px] z-10',
        className
      )}
    >
      <div className='flex pl-[36px] space-x-[10px] items-center'>
        {Array.from({ length: count }).map((_, index) => {
          const showMiniBullet =
            ((index === Math.max(activeIndex - 2, 0) && activeIndex !== 1) ||
              index === Math.min(activeIndex + 2, count)) &&
            index !== activeIndex

          return (
            <div
              key={index}
              className='flex items-center justify-center shrink-0 transition-all h-[8px] min-w-[8px]'
              style={{
                transform: `translateX(-${18 * activeIndex}px)`,
              }}
            >
              <div
                className={cn(bulletClassname, {
                  [`${activeBulletClassname} w-[40px] h-[8px] rounded-[5px]`]:
                    index === activeIndex,
                  'w-[6px] h-[6px] rounded-full': showMiniBullet,
                  'w-[8px] h-[8px] rounded-full':
                    index !== activeIndex && !showMiniBullet,
                })}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
