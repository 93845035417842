export enum UserActionEnum {
  READ = 'READ',
  CREATE_BOOK = 'CREATE_BOOK',
  CREATE_CHAPTER = 'CREATE_CHAPTER',
  EDIT_BOOK = 'EDIT_BOOK',
  EDIT_CHAPTER = 'EDIT_CHAPTER',
  READ_EBOOK_TRIAL_CHAPTER = 'READ_EBOOK_TRIAL_CHAPTER',
  VIEW_POPUP_BANNER = 'VIEW_POPUP_BANNER',
  CLICK_POPUP_BANNER = 'CLICK_POPUP_BANNER',
}
