import { gql } from 'graphql-request'

export const AVAILABLE_POPUP_BANNERS = gql`
  query AvailablePopupBanners(
    $screen: PopupBannerScreenEnum!
    $platform: PlatformEnum!
  ) {
    availablePopupBanners(screen: $screen, platform: $platform) {
      id
      imgPath
      link
    }
  }
`
