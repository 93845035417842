import { CREATE_USER_ACTION_LOG } from '@client/collections/UserActionLog/schemas/createUserActionLog'
import { gqlApiInstance } from '@client/init'
import { UserActionLogType } from '@models/userActionLog/UserActionLogType'

export function useUserActionLogAction() {
  async function createUserActionLog(
    userActionLog: UserActionLogType
  ): Promise<void> {
    await gqlApiInstance.request(CREATE_USER_ACTION_LOG, {
      createUserActionLogInput: { ...userActionLog },
    })
  }

  return {
    createUserActionLog,
  }
}
