import { ReactPortal, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { PopupProps } from './interface'
import { PopupDetail } from './PopupDetail'

export function Popup({
  data,
  showCloseAndDontShowAgainButton,
  closeAndDontShowAgain,
  close,
  handleClickPopup,
}: PopupProps) {
  const [component, setComponent] = useState<ReactPortal | null>(null)

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    setComponent(
      createPortal(
        <PopupDetail
          data={data}
          showCloseAndDontShowAgainButton={showCloseAndDontShowAgainButton}
          closeAndDontShowAgain={closeAndDontShowAgain}
          close={close}
          handleClickPopup={handleClickPopup}
        />,
        document.body
      )
    )

    return () => {
      document.body.style.overflow = ''
    }
  }, [
    data,
    showCloseAndDontShowAgainButton,
    closeAndDontShowAgain,
    close,
    handleClickPopup,
  ])

  return component
}
