import React from 'react'
import { BannerPaginationProps } from './interface'

export function withBannerPagination(
  Component: React.FC<BannerPaginationProps>
) {
  function WithBannerPagination(props: BannerPaginationProps) {
    return <Component {...props} />
  }

  return WithBannerPagination
}
